<template>
  <div>
    <header-main></header-main>
    <hero-1></hero-1>
    <why></why>
    <!-- <scheduling></scheduling> -->
    <!-- Planes Web -->
    <plans-sites></plans-sites>
    <!-- End Planes Web -->
    <!--  -->
    <steps></steps>
    <portfolio></portfolio>
    <!-- <our-clients></our-clients> -->
    <!-- <team></team> -->
    <footer-main></footer-main>
  </div>
</template>

<script>
import logo from "../assets/logo.svg";
import HeaderMain from "@/components/Header.vue";
import Hero1 from "@/components/Hero1.vue";
import Why from "@/components/Why.vue";
import PlansSites from "@/components/PlansSites.vue";
import Steps from "@/components/Steps.vue";
// import Scheduling from "@/components/Scheduling.vue";
// import Team from "@/components/Team.vue";
import Portfolio from "@/components/Portfolio.vue";
// import OurClients from "@/components/OurClients.vue";
import FooterMain from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    HeaderMain,
    Hero1,
    Why,
    PlansSites,
    Steps,
    // Scheduling,
    // Team,
    Portfolio,
    // OurClients,
    FooterMain,
  },
  data: () => ({
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
    importantLinks: [
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com",
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuejs.com/vuetify",
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs",
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify",
      },
    ],
    logo,
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com",
      },
      {
        text: "Roadmap",
        href: "https://vuetifyjs.com/introduction/roadmap/",
      },
      {
        text: "Frequently Asked Questions",
        href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
      },
    ],
  }),
  metaInfo() {
    return {
      title: "Appears |",
      titleTemplate:
        "%s Agencia Digital | Estamos reestructurando nuestro sitio web.",
      meta: [
        {
          name: "description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "keywords",
          content:
            "Appears, paginas web, paginas web villavicencio, sitio web, seo, sem, aplicaciones a la medida, software, google ads, facebook ads, publicidad en google, publicidad en facebook, publicidad en instagram, web, website, diseño web, desarrollo web",
        },
        {
          name: "author",
          content: "Appears",
        },
        {
          name: "robots",
          content: "Index, Follow",
        },
        {
          rel: "canonical",
          href: "https://appears.com.co/",
        },
        // Tags twitter
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:site",
          content: "@appears",
        },
        {
          name: "twitter:creator",
          content: "@appears",
        },
        {
          name: "twitter:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          name: "twitter:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "twitter:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        // Tags facebook
        {
          property: "og:locale",
          content: "es_CO",
        },
        {
          property: "og:site_name",
          content: "Appears",
        },
        {
          property: "og:url",
          content: "https://appears.com.co",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          property: "og:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          property: "og:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:secure_url",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:width",
          content: "705",
        },
        {
          property: "og:image:height",
          content: "427",
        },
      ],
    };
  },
};
</script>
<style scoped>
/* Hero */
.hero {
  background: var(--white-secondary);
}
.hero__box {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  /* padding: 80px 0; */
}
.hero__left {
  text-align: left;
}
.hero__left-h1 {
  font-size: 45px;
  color: var(--primary-opt);
  font-weight: 800;
  line-height: 0.9;
  margin-bottom: 0;
}
.hero__left-h2 {
  color: var(--black);
  font-size: 35px;
  font-weight: 800;
  line-height: 1;
}
.hero__left-h2 span {
  color: var(--positive);
  font-size: 35px;
  font-weight: 800;
  line-height: 1;
}
.hero__left-p {
  margin-top: 20px;
  margin-right: 20px;
  color: var(--black);
  font-size: 20px;
  font-weight: normal;
}
.hero__left-services img {
  height: 50px;
  margin-right: 5px;
  border: 2.5px solid var(--primary);
  border-radius: 15px;
}
.hero__left-tooltip {
  background: var(--primary) !important;
}

@media screen and (max-width: 991px) {
  .hero {
    width: 100%;
    height: 900px;
  }
  .hero__box {
    grid-template-columns: 100%;
    margin: 30px 30px 60px 30px;
  }
}
@media screen and (max-width: 480px) {
  .hero__left-h1 {
    font-size: 35px;
    line-height: 0.5;
  }
  .hero__left-h2,
  .hero__left-h2 span {
    font-size: 22px;
  }
}
/* Hero End*/
/* Scheduling */

.scheduling__container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding: 50px 20px;
  /* background: var(--secondary); */
  /* border-radius: 20px; */
}

.scheduling__content {
  display: grid;
  /* width: 50%; */
  /* margin: 0 20px; */
  /* max-width: 1200px; */
  align-items: center;
  border-radius: 10px;
}
.scheduling__text {
  height: 100%;
  text-align: center;
}

.scheduling__content-h1 {
  font-size: 40px;
  font-weight: 900;
  font-style: italic;
  line-height: 0.8;
}
.scheduling__content-h2 {
  margin-right: 50px;
  justify-content: center;
  position: relative;
  font-size: 25px;
  font-weight: 900;
  font-style: italic;
}
.scheduling__content-h2 span {
  position: absolute;
  top: -20px;
  font-size: 45px;
  font-family: "Kaushan Script", cursive;
}
.scheduling__content-p {
  font-size: 25px;
  font-weight: 600;
  line-height: 1.2;
}
.scheduling__content-hr {
  margin: 5px;
  background: var(--primary);
  height: 3px;
  border: none;
}
.scheduling__content-link {
  text-decoration: none;
}
.scheduling__content-btn {
  background: var(--primary) !important;
  width: 300px;
  margin-top: 5px;
  border-radius: 0;
  color: var(--white);
  font-size: 25px;
  font-weight: 900;
  font-style: italic;
  -webkit-box-shadow: 3px 6px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 6px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 6px 11px -6px rgba(0, 0, 0, 0.75);
}
.scheduling__cursor {
  position: absolute;
  transform: rotate(310deg);
  height: 100px;
}
@media screen and (max-width: 991px) {
  .scheduling__content {
    width: 80%;
  }
}
@media screen and (max-width: 650px) {
  .scheduling__content {
    grid-template-columns: 100%;
    width: 100%;
    text-align: center;
  }
  .scheduling__img {
    margin-top: 50px;
  }
  .scheduling__shapes {
    padding: 50px 0;
  }
  .scheduling__shape1,
  .scheduling__shape2 {
    height: 100px;
  }
}

@media screen and (max-width: 480px) {
  .scheduling {
    padding: 50px 0;
  }
  .scheduling__content-p {
    margin: 0 30px;
  }
}

@media screen and (max-width: 420px) {
  .scheduling__shapes {
    position: relative;
  }
  .scheduling__shape1 {
    display: none;
    top: 75px;
  }
  .scheduling__shape2 {
    display: none;
    top: 75px;
  }
  .scheduling__cursor {
    transform: rotate(280deg);
    height: 50px;
  }
}

@media screen and (max-width: 365px) {
  .scheduling__content-h1 {
    font-size: 35px;
  }
}

/* Scheduling End */
</style>
