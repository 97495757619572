<template>
  <section class="hero1">
    <div class="hero1__box">
      <div class="hero1__left">
        <span class="hero1__left-after-h1">POTENCIA TU NEGOCIO</span>
        <h1 class="hero1__left-h1">
          Paginas web
          <span class="hero1__left-h1-span">Villavicencio</span>
        </h1>
        <!-- <h2 class="">Villavicencio</h2> -->
        <p class="hero1__left-p">
          Todo un equipo desarrollando tu idea con el objetivo de llevarla a un
          nivel superior, descubrirás el impacto de la tecnología en esta era
          digital.
        </p>
        <!-- <div class="hero1__left-services">
          <v-tooltip bottom color="purple" class="hero1__left-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                class="hero1__left-services-icon-social"
                color="purple"
                >mdi-facebook</v-icon
              >
            </template>
            <span>Facebook</span>
          </v-tooltip>
          <v-tooltip bottom color="purple" class="hero1__left-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                class="hero1__left-services-icon-social"
                color="purple"
                >mdi-instagram</v-icon
              >
            </template>
            <span>Instagram</span>
          </v-tooltip>
          <v-tooltip bottom color="purple" class="hero1__left-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                class="hero1__left-services-icon-social"
                color="purple"
                >mdi-linkedin</v-icon
              >
            </template>
            <span>LinkedIn</span>
          </v-tooltip>
          <v-tooltip bottom color="purple" class="hero1__left-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                class="hero1__left-services-icon-social"
                color="purple"
                >mdi-twitter</v-icon
              >
            </template>
            <span>Twitter</span>
          </v-tooltip>
          <v-tooltip bottom color="purple" class="hero1__left-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                class="hero1__left-services-icon-social"
                color="purple"
                >mdi-youtube</v-icon
              >
            </template>
            <span>YouTube</span>
          </v-tooltip>
          <v-tooltip bottom color="purple" class="hero1__left-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                class="hero1__left-services-icon-social"
                color="purple"
                >mdi-youtube</v-icon
              >
            </template>
            <span>TikTok</span>
          </v-tooltip>
        </div> -->
      </div>
      <div class="hero1__right">
        <img
          class="hero1__right-rockets"
          src="/statics/img/shapes/rockets.svg"
          alt="Imagen temporal load"
        />
        <img
          class="hero1__right-space"
          src="/statics/img/shapes/space.svg"
          alt="Imagen temporal load"
        />
        <img
          class="hero1__right-moon"
          src="/statics/img/shapes/moon.svg"
          alt="Imagen temporal load"
        />
        <img
          class="hero1__right-rocket"
          src="/statics/img/shapes/rocket.svg"
          alt="Imagen temporal load"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Hero1",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.hero1 {
  background: var(--white-secondary);
  background-image: url(/statics/img/shapes/back-stars.svg);
  width: 100%;
}

.hero1__box {
  display: grid;
  margin: auto;
  max-width: 1200px;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  /* width: 100%; */
  padding: 100px 0;
}
.hero1__left {
  font-family: "Poppins";
  text-align: left;
  /* background: blue; */
}
.hero1__left-after-h1 {
  color: #474747;
  font-size: 45px;
  font-weight: 700;
  line-height: 0;
}
.hero1__left-h1 {
  color: var(--primary-opt);
  margin: 0;
  font-size: 55px;
  font-weight: 600;
  line-height: 0.8;
}
.hero1__left-h1-span {
  display: block;
  color: var(--gray);
  font-size: 50px;
  font-weight: 600;
  line-height: 1.1;
}
.hero1__left-h2 span {
  color: var(--positive);
  font-size: 35px;
  font-weight: 800;
  line-height: 1;
}
.hero1__left-p {
  /* margin-top: 20px; */
  margin-right: 20px;
  color: var(--black);
  font-size: 20px;
  font-weight: normal;
}
.hero1__left-services-icon-social {
  height: 50px;
  width: 50px;
  margin-right: 5px;
  border: 2.5px solid var(--primary);
  border-radius: 15px;
}
.hero1__left-tooltip {
  background: var(--primary) !important;
}
.hero1__right {
  position: relative;
  height: 100%;
}
.hero1__right-rockets {
  position: absolute;
  top: -300px;
  right: -200px;
  z-index: 1;
  animation: rotation 30s linear infinite;
}
/* .bg-position{
  position: relative;
  overflow: hidden;
}
.background-shape svg {
    position: absolute;
    opacity: 0.30;
}
svg.shape_1 {
    bottom: -50px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}
svg.shape_2 {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%);
}
svg.shape_3 {
    top: 50px;
    right: -200px;
    animation: rotation 20s linear infinite;
}
svg.shape_4 {
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
       -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
         -o-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}
svg.shape_5 {
    top: -23%;
    left: 50%;
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%);
}
svg.shape_6 {
    left: -7%;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}
svg.shape_7 {
    right: -9%;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
} */
.rotation {
  -webkit-animation: rotation 30s linear infinite;
  animation: rotation 30s linear infinite;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .bounce-1 {
  -webkit-animation: bounce-1 10s ease infinite;
  animation: bounce-1 10s ease infinite;
} */
/* @keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
} */

.draw-in {
  stroke-dasharray: 1000;
  stroke-dashoffset: 10;
  -webkit-animation: draw 15s ease-in-out alternate infinite;
  animation: draw 15s ease-in-out alternate infinite;
}

@-webkit-keyframes draw {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
/*---------------- abackground Animation End ---------------*/
.hero1__right-space {
  position: absolute;
  top: -300px;
  right: -200px;
  z-index: 1;
}
.hero1__right-space::after {
  background: rgba(0, 0, 0, 0) url("/statics/img/shapes/space.svg") no-repeat
    scroll left center;
  content: "";
  position: absolute;
  top: 0px;
  left: -400px;
  width: 900px;
  height: 900px;
  z-index: -1;
  opacity: 0.2;
  animation: rotation 50s linear infinite;
}
.hero1__right-moon {
  position: absolute;
  height: 100px;
  top: -50px;
  right: -150px;
  z-index: 2;
}
.hero1__right-rocket {
  position: absolute;
  left: 100px;
  height: 500px;
  transform: rotate(45deg);
  z-index: 2;
  animation: MoveUpDown 2s linear infinite;
}
@keyframes MoveUpDown {
  0%,
  100% {
    top: 0;
  }
  50% {
    top: 10px;
  }
}

@media screen and (max-width: 991px) {
  .hero1__box {
    grid-template-columns: 100%;
    padding: 30px 30px 60px 30px;
  }
  .hero1__right-video-box {
    margin-top: 30px;
  }
}
@media screen and (max-width: 480px) {
  .hero1__left-h1 {
    font-size: 35px;
    line-height: 0.5;
  }
  .hero1__left-h2,
  .hero1__left-h2 span {
    font-size: 22px;
  }
}
</style>
